import React, { Component } from 'react'

import SectorCard from '../components/cards/sector'

class Sectors extends Component {

  render() {
    let { sectors } = this.props
    console.log(sectors)
    return (
      <section className="sectors">
        <div className="sectors__inner container">
          { sectors && sectors.map((el, i) => (<SectorCard key={i} {...el} />)) }
        </div>
      </section>
    )
  }
}

export default Sectors