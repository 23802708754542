import React from 'react'

import Testimonial from '../components/testimonial'
import ThreeColumnContent from '../components/three-column-content'
import QuickContact from '../components/quick-contact'
import BannerImage from '../components/banner-image'
import Instagram from '../components/instagram'
import CaseStudies from '../components/case-studies'
import Sectors from '../components/sectors'
import OurServices from '../components/our-services'
import ContentVideoImage from '../components/content-video-image'
import VideoImage from '../components/video-image'
import ContactPage from '../components/contact-page'
import BasicPage from '../components/basic-page'
import TitleContent from '../components/title-content'
import Letters from '../components/letters'
import ImageContent from '../components/image-content'
import SlimContact from '../components/slim-contact'
import Team from '../components/team'
import AllTeam from '../components/all-team'
import LandingFormBanner from '../components/landing-form-banner';
import ThreeColumnImage from '../components/three-column-image';
import TwoColumnRepeater from '../components/two-column-repeater';
import FullWidthImage from '../components/full-width-image';
import TwoColumnImageContent from '../components/two-column-image-content';
import PackageTwoColumn from '../components/package-two-column';
import PackageThreeColumn from '../components/package-three-column';
import HeadingContentLanding from '../components/heading-content-landing';
import DronesInfoImages from '../components/drones-info-images';
import LandingContact from '../components/landing-contact';
import AltLandingFormBanner from '../components/alternate-landing-form-banner';
import LandingContentVideo from '../components/landing-content-video';
import EmbedContent from '../components/embed-content'
import CrossPartnerBlock from '../components/cross-partner-block'

const RenderBlock = (param, el, i) => {
  let block = {
    'WordPressAcf_testimonial': (el, i) => (<Testimonial {...el} key={i} />),
    'WordPressAcf_three_column_content': (el, i) => (<ThreeColumnContent {...el} key={i} />),
    'WordPressAcf_quick_contact': (el, i) => (<QuickContact {...el} key={i} />),
    'WordPressAcf_banner_image': (el, i) => (<BannerImage {...el} key={i} />),
    'WordPressAcf_instagram_feed': (el, i) => (<Instagram key={i} />),
    'WordPressAcf_case_studies': (el, i) => (<CaseStudies key={i} />),
    'WordPressAcf_sectors': (el, i) => (<Sectors {...el} key={i} />),
    'WordPressAcf_our_services': (el, i) => (<OurServices {...el} key={i} />),
    'WordPressAcf_content_video_image': (el, i) => (<ContentVideoImage {...el} key={i} />),
    'WordPressAcf_video_image': (el, i) => (<VideoImage {...el} key={i} />),
    'WordPressAcf_contact_page': (el, i) => (<ContactPage {...el} key={i} />),
    'WordPressAcf_basic_page': (el, i) => (<BasicPage {...el} key={i} />),
    'WordPressAcf_page_header': (el, i) => (<TitleContent {...el} key={i} />),
    'WordPressAcf_letters': (el, i) => (<Letters {...el} key={i} />),
    'WordPressAcf_image_content': (el, i) => (<ImageContent {...el} key={i} />),
    'WordPressAcf_slim_contact': (el, i) => (<SlimContact {...el} key={i} />),
    'WordPressAcf_team': (el, i) => (<Team {...el} key={i} />),
    'WordPressAcf_all_team': (el, i) => (<AllTeam {...el} key={i} />),
    'WordPressAcf_landing_form_banner' : (el, i) => (<LandingFormBanner {...el} key={i} />),
    'WordPressAcf_three_column_image' : (el, i) => (<ThreeColumnImage {...el} key={i} />),
    'WordPressAcf_two_column_repeater' : (el, i) => (<TwoColumnRepeater {...el} key={i} />),
    'WordPressAcf_full_width_image' : (el, i) => (<FullWidthImage {...el} key={i} />),
    'WordPressAcf_two_column_image_content' : (el, i) => (<TwoColumnImageContent {...el} key={i} />),
    'WordPressAcf_package_two_column' : (el, i) => (<PackageTwoColumn {...el} key={i} />),
    'WordPressAcf_package_three_column' : (el, i) => (<PackageThreeColumn {...el} key={i} />),
    'WordPressAcf_heading_content_landing' : (el, i) => (<HeadingContentLanding {...el} key={i} />),
    'WordPressAcf_drone_info_images' : (el, i) => (<DronesInfoImages {...el} key={i} />),
    'WordPressAcf_landing_contact' : (el,i) => (<LandingContact {...el} key={i} />),
    'WordPressAcf_alternate_landing_form_banner' : (el, i) => (<AltLandingFormBanner {...el} key={i} />),
    'WordPressAcf_landing_content_video' : (el, i) => (<LandingContentVideo {...el} key={i} />),
    'WordPressAcf_embed_content' : (el, i) => (<EmbedContent {...el} key={i} />),
    'WordPressAcf_cross_partner_block' : (el, i) => (<CrossPartnerBlock {...el} key={i} />),
  }[param]

  if (!block) return

  return block(el, i)
}

export default RenderBlock
