import React from 'react'

import { Asset, Enviro, Energy, Mining, Technology, Transport, Water, SlimArrowRight } from '../components/icons'

const RenderIcon = (param) => {
  let icon = {
    'Asset': () => (<Asset color="#FFF" />),
    'Enviro': () => (<Enviro color="#FFF" />),
    'Energy': () => (<Energy color="#FFF" />),
    'Mining': () => (<Mining color="#FFF" />),
    'Technology': () => (<Technology color="#FFF" />),
    'Transport': () => (<Transport color="#FFF" />),
    'Water': () => (<Water color="#FFF" />),
    'SlimArrowRight': () => (<SlimArrowRight color="#FFF" />),
  }[param]

  if (!icon) return

  return icon()
}

export default RenderIcon
